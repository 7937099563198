
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import BlobImagesManager from "@/lib/blobImagesManager";
import { getChatClient } from "@/services/chat";
import { MXCMethods } from "@/services/chat/client";

const blobImgMng = new BlobImagesManager();

@Options({})
export default class SecuredImage extends Vue {
  @Prop({ require: true })
  readonly src!: string;

  @Prop({ require: false })
  readonly class!: string;

  @Prop({ default: MXCMethods.Full })
  readonly mode!: MXCMethods;

  @Prop()
  readonly attr?: string;

  isLoading = false;
  isEmpty = false;
  private finaleSrc = "";

  @Watch("src")
  onSourceChanged(val: string, oldVal: string) {
    this.refresh();
  }

  beforeMount(): void {
    this.refresh();
  }

  unmounted(): void {
    blobImgMng.unset(this.finaleSrc);
  }

  refresh(): void {
    this.isLoading = true;

    if (!this.src) {
      this.isEmpty = true;
      this.isLoading = false;
      return;
    }

    // src is url or blob
    if (typeof this.src === "string") {
      this.finaleSrc = this.src + "";
      if (this.src.startsWith("mxc://")) {
        this.finaleSrc = getChatClient().getMXCContentUrl(
          this.finaleSrc,
          this.mode,
          this.attr
        );
      }
      if (!this.src.startsWith("blob:")) {
        blobImgMng
          .get(this.finaleSrc)
          .then((url) => {
            // do not modify finaleSrc else it s generate an infinite loop with the update refresh...
            // this.$el.src = url;
            this.finaleSrc = url;
            this.isEmpty = false;
          })
          .catch(() => {
            this.isEmpty = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        // blob
        this.isEmpty = false;
        this.isLoading = false;
      }
    } else {
      // src is File selected
      this.finaleSrc = window.URL.createObjectURL(this.src as Blob);
      this.isEmpty = false;
      this.isLoading = false;
    }
  }

  get classParameter(): string {
    if (this.class) {
      return this.class;
    }
    return "";
  }
}
