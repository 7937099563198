import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: _normalizeClass([
      _ctx.classParameter,
      { 'img-loader': _ctx.isLoading },
      { 'img-empty': _ctx.isEmpty },
    ]),
    src: _ctx.finaleSrc
  }, null, 10, _hoisted_1))
}